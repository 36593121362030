import viewtype from 'widgets/toolbox/viewtype';
import { getContentByUrl } from 'widgets/toolbox/ajax';
import { appendParamsToUrl } from 'widgets/toolbox/util';
import { extend } from 'widgets/toolbox/util';
import Glide from '@glidejs/glide/dist/glide.esm';
import $ from 'jquery';
import Widget from '../Widget';

const GLIDEJS_OPTIONS = {
    perView: 1,
    gap: 0,
    rewind: false,
    bound: true,
    dragThreshold: 120,
    animationDuration: 300,
    animationTimingFunc: 'linear',
    loop: false,
    classes: {
        swipeable: 'b-carousel--swipeable',
        dragging: 'b-carousel--dragging',
        peekingSlide: 'b-carousel__slide--peeking',
        loaded: 'b-carousel--loaded',
        direction: {
          ltr: 'b-carousel--ltr',
          rtl: 'b-carousel--rtl'
        },
        type: {
          slider: 'b-carousel--slider',
          carousel: 'b-carousel--carousel'
        },
        slide: {
          clone: 'b-carousel__slide--clone',
          active: 'b-carousel__slide--active'
        },
        arrow: {
          disabled: 'b-carousel__arrow--disabled'
        },
        nav: {
          active: 'b-carousel__bullet--active'
        },
        visibleNav: 'b-carousel__bullet--visible',
        disabled: 'b-carousel--disabled'
      }
};

/**
 * @description Base carousel implementation
 * @docs https://glidejs.com/docs/options/
 */
export default class Carousel extends Widget {
    prefs() {
        return {
            carouselOptions: {},
            ...super.prefs()
        };
    }

    init() {
        this.carouselTracked = false;
        this.allowStateChange = true;
        this.disabled = false;
        this.options = {};
        this.initOptions();

        if (!this.prefs().activeOnViewType) {
            this.activate();
        } else {
            this.activateIfViewType();
            this.eventBus().on('viewtype.change', 'activateIfViewType');
        }


        const activatePDPVariant = window.activatePDPVariant;
        window.activatePDPVariant = (variant) => {
            if (activatePDPVariant) {
                activatePDPVariant(variant);
            }
            setTimeout(() => {
                this.update(this.options);
            }, 100);
        };
    }

    initOptions() {
        extend(this.options, GLIDEJS_OPTIONS, this.prefs().carouselOptions || {});
    }

    prepareOptions() {
        if (this.options.breakpoints) {
            this.options.breakpoints[9999] = {
                perView: this.options.perView
            };
        }
    }

    onIsInViewport() {
        if (!this.carouselTracked) {
            this.eventBus().emit('carousel.run', this.carousel.index);
        }
        this.carouselTracked = true;
    }

    initEvents() {
        this.carousel.on(['mount.after', 'resize', 'update'], () => {
            this.ref('self').attr('data-per-view', this.carousel.settings.perView);
        });

        this.carousel.on(['mount.after', 'resize'], () => {
            // change carousel type to 'slider'
            // if there are less slides than configured for viewing
            // to avoid duplicate slides
            let carouselType = 'slider';
            if (this.carousel.settings.loop) {
                this.disabled = true;
                if (this.carousel._c.Html.slides.length > this.carousel.settings.perView) {
                    this.disabled = false;
                    carouselType = 'carousel';
                }
            }
            this.carousel.update({
                type: carouselType
            });

            const maxIndex = this.carousel._c.Html.slides.length - this.carousel.settings.perView;

            // scroll to first slide on last page to avoid empty space
            if (maxIndex >= 0 && this.carousel.index > maxIndex) {
                this.goto(maxIndex);
            }

            // disable carousel if there are less slides than configured for viewing
            if (this.carousel._c.Html.slides.length > this.carousel.settings.perView) {
                this.enable(false);
            } else {
                this.disable(false);
            }
        });

        // disable navigation buttons at the scroll edges
        this.carousel.on(['mount.after', 'run.after', 'resize'], () => {
            if (!this.carousel.settings.loop && this.carousel.isType('slider')) {
                const maxIndex = this.carousel._c.Html.slides.length - this.carousel.settings.perView;
                this.has('btnPrev', btnPrev => btnPrev.toggleClass(this.carousel.settings.classes.arrow.disabled, this.carousel.index <= 0));
                this.has('btnNext', btnNext => btnNext.toggleClass(this.carousel.settings.classes.arrow.disabled, this.carousel.index >= maxIndex));
            }
            if (this.isReccomendationCarousel()) {
                this.clearPeekingSlide();
                this.markPeekingSlide();
            }
        });

        this.carousel.on(['run.before'], () => {
            this.clearPeekingSlide();
        });

        this.carousel.on(['mount.after'], () => {
            this.ref('self').toggleClass(this.carousel.settings.classes.loaded, true);
        });

        // mark bullets for slides, which are visible for current scroll position
        this.has('bullets', bullets => this.carousel.on(['mount.after', 'run.after', 'resize'], () => {
            const bulletsCount = bullets.get().children.length;
            Array.from(bullets.get().children).forEach((bullet, bulletIndex) => {
                if (this.isReccomendationCarousel()) {
                    const jBullet = $(bullet);
                    jBullet.removeAttr('hidden');
                    if (bulletIndex % this.carousel.settings.perView !== 0) {
                        jBullet.attr('hidden', 'hidden');
                    }
                }
                var bulletVisible = false;
                for (var i = this.carousel.index; i < this.carousel.index + this.carousel.settings.perView; i++) {
                    bulletVisible = bulletVisible || (i % bulletsCount === bulletIndex);
                }
                bullet.classList[bulletVisible ? 'add' : 'remove'](this.carousel.settings.classes.visibleNav);
            });
        }));
    }

    clearPeekingSlide() {
        if (this.carousel._c && this.carousel._c.Html && this.carousel._c.Html.slides) {
            $(this.carousel._c.Html.slides).removeClass(this.carousel.settings.classes.peekingSlide);
        }
    }

    isReccomendationCarousel() {
        const els = this.has('recomendationCarousel') && this.ref('recomendationCarousel').els;
        return els && els.length > 0;
    }

    markPeekingSlide() {
        if (this.carousel._c && this.carousel._c.Html && this.carousel._c.Html.slides) {
            const allSlides = this.carousel._c.Html.slides;
            const allNextSiblings = $(allSlides).filter('.'+GLIDEJS_OPTIONS.classes.slide.active).nextAll();
            $(allNextSiblings[this.carousel.settings.perView - 1]).addClass(this.carousel.settings.classes.peekingSlide);
        }
    }

    activateIfViewType() {
        const viewType = viewtype.getViewType();
        if (this.prefs().activeOnViewType.includes(viewType)) {
            this.activate();
        } else {
            this.deactivate();
        }
    }

    enable(preventStateChange = true) {
        if (this.disabled || (!preventStateChange && !this.allowStateChange)) {
            return;
        }

        this.carousel.enable();
        this.ref('slider').removeClass(this.carousel.settings.classes.disabled);

        if (preventStateChange) {
            this.allowStateChange = false;
        }
    }

    disable(preventStateChange = true) {
        this.ref('slider').addClass(this.carousel.settings.classes.disabled);

        if (this.disabled || (!preventStateChange && !this.allowStateChange)) {
            return;
        }

        this.carousel.disable();

        if (preventStateChange) {
            this.allowStateChange = false;
        }
    }

    activate() {
        if (this.isReccomendationCarousel() && this.prefs().carouselUrl) {
            this.ref('slider').removeClass('loading');
            this.activateCarousel();
            setTimeout(() => {
                this.activateAsyncCarousel();
            }, 200);
        } else {
            this.activateCarousel();
        }
    }

    activateCarousel() {
        if (!this.cleanUpCarousel || this.ref('loading').length) {
            const carousel = this.ref('slider').get();

            if (carousel) {
                this.prepareOptions();
                this.carousel = new Glide(carousel, this.options);

                this.initEvents();
                this.carousel.mount();

                this.cleanUpCarousel = () => {
                    if (this.carousel) {
                        this.carousel.destroy();
                        this.carousel = void 0;
                        this.cleanUpCarousel = void 0;
                    }
                };

                this.onDestroy(this.cleanUpCarousel);
            }
        }
    }

    activateAsyncCarousel() {
        const carouselEl = this.ref('self');
        const carouselType = this.prefs().carouselType;
        const isPdP = this.prefs().carouselPdp;
        let carouselUrl = this.prefs().carouselUrl;
        const recommenderType = this.prefs().recommenderType;
        let carouselParams;

        if (recommenderType && recommenderType === 'visually-similar') {
            const productDetail = this.parents.find(p => p.config.widget === 'productDetail');
            const productId = productDetail && productDetail.prefs().variationGroupSku;

            if (productId) {
                carouselParams = {
                    recommenderType,
                    productId
                };
            }
        }

        if (carouselParams) {
            carouselUrl = appendParamsToUrl(carouselUrl, carouselParams);
        }

        getContentByUrl(carouselUrl)
            .then(response => {
                if (response) {
                    if (carouselType === "duel" && this.slidesCounter(response) < 2) {
                        carouselEl.hide();
                        return;
                    }
                    if (carouselType === "duel" && isPdP) {
                        this.duelContentSquareTrack();
                    }
                    this.render('recommendations', {}, this.ref('slides'), response);
                    this.activateCarousel();
                }
            }).catch(error => {
                if (error) {
                    carouselEl.hide();
                }
            });
    }

    deactivate() {
        if (this.cleanUpCarousel) {
            this.cleanUpCarousel();
            this.cleanUpCarousel = void 0;
        }
    }

    update(options = {}) {
        this.carousel.update(options);
    }

    refresh(options = {}) {
        this.deactivate();
        extend(this.options, options || {});
        this.activate();
    }

    goto(index = 0) {
        this.carousel.go('=' + index);
    }

    slidesCounter (response) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(response, 'text/html');
        const carouselSlides = doc.querySelector('.b-carousel__slides');
        return carouselSlides ? carouselSlides.querySelectorAll('.b-carousel__slide').length : 0;
    }

    duelContentSquareTrack () {
        if (window._uxa) {
            window._uxa.push(['setCustomVariable', 11, 'type', 'ugc_carousel']);
        }
    }
}