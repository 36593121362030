import Widget from '../Widget';

export default class EditorialHomepage extends Widget {

    container;
    totalArticles;
    loadMoreBtn;
    loadMoreUrl;

    init() {
        super.init();
        this.initialiseLoadMore();
    }

    prefs() {
        return {
            ...super.prefs(),
            loadMoreUrl: document.querySelector('#editorial-homepage__load-more-url').innerText,
            pageSize: 6
        };
    }

    disableLoadMore() {
        this.loadMoreBtn.hidden = true;
    }

    async loadMore() {
        const loaded = document.querySelectorAll('.editorial-homepage__article').length;
        const res = await window.fetch(this.prefs().loadMoreUrl + '?offset=' + loaded + '&limit=' + this.prefs().pageSize);
        const articles = Array.from($(await res.text()).filter((_, el) => el.classList?.contains('editorial-homepage__article')));
        articles.forEach(el => this.container.append(el));

        if (loaded + this.prefs().pageSize >= this.totalArticles) {
            this.disableLoadMore();
        }
    }

    enableLoadMore() {
        this.loadMoreBtn.addEventListener('click', () => this.loadMore());
        this.loadMoreBtn.hidden = false;
    }

    initialiseLoadMore() {
        this.totalArticles = Number(document.querySelector('#editorial-articles-total')?.innerText);
        this.container = document.querySelector('#editorial-homepage__articles');
        this.loadMoreBtn = document.querySelector('#editorial-homepage__load-more');
        if (this.totalArticles > this.prefs().pageSize) {
            this.enableLoadMore();
        }
    }


}